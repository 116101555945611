import React from 'react'
import { mapSubModuleFields, textStyle } from '../../utils'
import { Container, Row } from 'react-bootstrap'
import StandardCard from './StandardCard'
import ProviderCardItem from './ProviderCard'

export const CardModule = ({
  heading,
  subHeading,
  subModules,
  style,
  orgId,
  payerLink,
}) => (
  <Container fluid className="card-outer-container">
    <Row
      className="py-0 px-3 m-auto text-center card-outer"
      style={{ maxWidth: '1200px' }}
    >
      <div className="text-center my-3 module-heading-wrapper">
        <h2
          className="my-3 module-heading"
          css={textStyle(style?.headingColor)}
        >
          {heading}
        </h2>
        <div className="my-3 card-subheading">
          <p css={textStyle(style?.subHeadingColor)}>{subHeading}</p>
        </div>
      </div>
      {subModules &&
        subModules.map((item, i) => (
          <React.Fragment key={i}>
            {item.containerType === 'Card Item' ? (
              <StandardCard {...{ ...item, orgId, payerLink }} />
            ) : (
              <ProviderCardItem {...{ ...item, orgId, payerLink }} />
            )}
          </React.Fragment>
        ))}
    </Row>
  </Container>
)

export const mapCardProps = props => mapSubModuleFields(props)
